<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-setting display-6 text-primary"></i></div>
              <div>
                <h4 class="fw-medium font-size-20 text-info mb-0"> {{ title }}</h4>
                <small class="d-block font-size-13 text-muted fw-normal">Account Info &amp; Password</small>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="mt-1">
                  
                    <div class="row">
                      <div class="col-md-4 col-12">
                        <h5 class="font-size-14 mb-1 text-dark">Login Username</h5>
                        <p class="mb-3 text-primary">{{username || '-'}}</p>
                      </div>
                      <div class="col-md-4 col-12" v-if="account_type=='merchant'">
                        <h5 class="font-size-14 mb-1 text-dark">Merchant Code</h5>
                        <p class="mb-3 text-primary">{{merchant_code || '-'}}</p>
                      </div>
                      <div class="col-md-4 col-12" v-if="account_type=='merchant'">
                        <h5 class="font-size-14 mb-1 text-dark">Merchant Key</h5>
                        <p class="mb-3 text-primary">{{merchant_key || '-'}}</p>
                      </div>
                    </div>
                  <form>
                    <div class="mb-3 position-relative">
                      <label>Name</label>
                      <input
                        v-model="formData.name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        name="name"
                        :class="{
                          'is-invalid':
                            submitted && $v.formData.name.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.formData.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.name.required"
                          >Name is required.</span
                        >
                      </div>
                    </div>
                    <div class="mb-3 position-relative">
                      <label>Current Password</label>
                      <input
                        v-model="formData.current_password"
                        :type="passwordFieldType_current"
                        class="form-control"
                        placeholder="Current Password"
                        name="name"
                        :class="{
                          'is-invalid':
                            submitted && $v.formData.current_password.$error,
                        }"
                      />
                      <div
                        v-if="submitted && $v.formData.current_password.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.formData.current_password.required"
                          >Current password is required.</span
                        >
                      </div>
                      <div
                        id="eye_current"
                        class="user-select-none"
                        @click="switchVisibility_current()"
                      >
                        <i
                          :class="classicon_current"
                          id="classicon_current"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>New Password</label>
                          <div>
                            <input
                              v-model="formData.password"
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              :class="{
                                'is-invalid':
                                  submitted && $v.formData.password.$error,
                              }"
                              placeholder="New Password"
                            />
                            <div
                              v-if="submitted && $v.formData.password.$error"
                              class="invalid-feedback"
                            >
                              <span v-if="!$v.formData.password.required"
                                >New password is required.</span
                              >
                              <span v-if="!$v.formData.password.minLength"
                                >Password must be at least 8 characters.</span
                              >

                              <span v-if="!$v.formData.password.alpha">
                                Password must contain at least one number and
                                both lower and uppercase letters and special
                                characters. (e.g.: MercHant357#@, Admin12#@,
                                etc.)</span
                              >
                            </div>
                            <div
                              id="eye"
                              class="user-select-none"
                              @click="switchVisibility()"
                            >
                              <i
                                :class="classicon"
                                id="eyetype"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3 position-relative">
                          <label>Confirm Password</label>
                          <input
                            v-model="formData.confirmPassword"
                            :type="passwordFieldType2"
                            name="confirmPassword"
                            class="form-control"
                            :class="{
                              'is-invalid':
                                submitted && $v.formData.confirmPassword.$error,
                            }"
                            placeholder="Confirm Password"
                          />
                          <div
                            v-if="
                              submitted && $v.formData.confirmPassword.$error
                            "
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.formData.confirmPassword.required"
                              >Confirm password is required.</span
                            >
                            <span
                              v-else-if="
                                !$v.formData.confirmPassword.sameAsPassword
                              "
                              >Confirm password does not match the new
                              password.</span
                            >
                          </div>
                          <div
                            id="eye2"
                            class="user-select-none"
                            @click="switchVisibility2()"
                          >
                            <i
                              :class="classicon2"
                              id="eyetype2"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <p class="text-muted font-size-13">
                        <i
                          class="
                            uil uil-exclamation-triangle
                            font-size-16
                            text-warning
                            me-2
                          "
                        ></i>
                        Password is Case Sensitive.
                      </p>
                    </div>
                    <div class="mb-3 d-flex">
                      <button type="button" @click="resetData" class="btn btn-light me-2" :disabled="loading || buttonLoading[1] ">
                      <i class="uil-redo me-1"></i> 
                      <span v-if="buttonLoading[1]"> Resetting..</span>
                      <span v-else>  Reset </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[1]" role="status" aria-hidden="true"></span>
                      </button>

                      <button type="button" @click="save" class="btn btn-info me-2" :disabled="loading || buttonLoading[0]">
                      <i class="uil uil-check me-1"></i>
                      <span v-if="buttonLoading[0]"> Submitting...</span>
                      <span v-else>  Submit </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[0]" role="status" aria-hidden="true"></span>
                      </button>
                      
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required, minLength, sameAs, helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
/**
 * Settings page
 */
 const PageTitle = "Settings"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "OneTouch Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      passwordFieldType_current: "password",
      classicon_current: "uil uil-eye-slash",
      passwordFieldType: "password",
      classicon: "uil uil-eye-slash",
      passwordFieldType2: "password",
      classicon2: "uil uil-eye-slash",
      username: "",
      merchant_code: "",
      merchant_key: "",
      account_type:"",
      formData: {
        name:"",
        current_password: "",
        password: "",
        confirmPassword: "",
        otp: "",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      current_password: {
        required,
      },
      password: {
        required,
        alpha,
        minLength: minLength(8),
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.reload()
    if (this.account_type=='merchant'){
      this.getMerchantData();
    }else{
      console.log("another data")
      this.getData();
    }
    
  }, 
  created(){
   
  },
  methods:{
     resetData() {
      this.submitted = false;
      this.formData.current_password = "";
      this.formData.password = "";
      this.formData.confirmPassword = "";
    },
    switchVisibility_current() {
      (this.classicon_current =
        this.classicon_current === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType_current =
          this.passwordFieldType_current === "password" ? "text" : "password");
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
     switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    getMerchantData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        axios({
            method: "post",
            url:  appConfig.APIHost + 'controller/account/getDashboardData',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              //this.reload()
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
   },
   getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              //this.reload()
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    save() {
        this.submitted = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[0] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername",  this.accessUsername);
            bodyFormData.append("name",  this.formData.name);
            bodyFormData.append("currentPassword",  this.formData.current_password);
            bodyFormData.append("password",  this.formData.password);
            var postUrl = ""
            if (this.accessUsername=='admin'){
              postUrl = appConfig.DemoAPI
            }else{
              postUrl = appConfig.APIHost + 'controller/account/updateSetting'
            }

            const sendRequest = async () => {
              try {
                const response = await 
                axios.post(
                   postUrl, 
                   bodyFormData, {
                   headers: { "Content-Type": "multipart/form-data" },
                });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    const updateData = {
                        "name": this.formData.name,
                    }
                    this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                    this.formData.name = "";
                    this.resetData()
                    this.reload()

                    Swal.fire({
                      icon: 'success',
                      title: 'Password Updated',
                      html: `Password have been successfully updated!`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                    .then(() => {
                      this.$router.push({
                        path: "/logout",
                      });
                    })
                  }
                  else if (resData.status == 440) {
                    console.log(resData)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                      this.$refs.commonFunc.clearData()
                      this.$router.push({
                          path: "/login",
                    });
                  }
                  else{
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  
                  this.loading = false;
                  this.submitted = false
                  this.buttonLoading[0] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[0] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                }
            };

            sendRequest();
        }
    },
    
    reset() {
        this.submitted = true
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("phone",  this.accessPhone);
            axios({
                method: "post",
                url: appConfig.DemoAPI,
                data: bodyFormData,
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((response)=>{
                var resData = response.data;
                if (resData.status == 200) {
                  console.log(resData)
                  Swal.fire({
                    icon: 'success',
                    title: 'Deleted',
                    html: `API ID and API Hash have been successfully deleted and reset!`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done')
                  })
                  const updateData = {
                    "apiID": 'YOUR_API_ID',
                    "apiHash": 'YOUR_API_HASH',
                 }
                 this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                 this.reload()
                   
                } 
                else if (resData.status == 401){
                  Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                    this.$refs.commonFunc.clearData()
                    this.$router.push({
                        path: "/login",
                  });
                }
                else {
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${resData.message}.`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
                }
                this.loading = false;
                this.submitted = false
                this.buttonLoading[1] = false
                this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading[1] = false
                this.loading = false;
                this.$Progress.finish();
                //Swal.fire("Error", error, "error");
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${error}.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
          });
        }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.username = data.username;
          this.merchant_key = data.merchant_key;
          this.merchant_code = data.merchant_code;
          this.formData.name = data.name;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>
<style scoped>
#eye_current,
#eye,
#eye2,
#eye3{
  position: absolute;
  top: 32px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 30px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
}
</style>